<script lang="ts">
  import type { LangType } from '@customTypes/newscraft'
  import { useTranslations } from '@i18n/utils'
  import { onMount } from 'svelte'

  export let adSlot: string

  onMount(() => {
    // @ts-ignore
    ;(adsbygoogle = window.adsbygoogle || []).push({})
  })
</script>

<ins
  class="adsbygoogle"
  style="display:block"
  data-ad-format="fluid"
  data-ad-layout-key="-gf+18-5a-gr+1eg"
  data-ad-client="ca-pub-6995062421702116"
  data-ad-slot={adSlot}
></ins>
