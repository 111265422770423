<script lang="ts">
  import { adSenseInFeed } from './../../adsense.ts'
  import AdSenseInFeed from '@components/FGMedia/AdSenseInFeed.svelte'
  import BrandedContent from '@components/FGMedia/BrandedContent.svelte'
  import BrandedContentCard from '@components/FGMedia/BrandedContentCard.svelte'
  import type { BrandedContentType } from '@customTypes/brandedContent'
  import type { LangType } from '@customTypes/newscraft'
  import { useTranslations } from '@i18n/utils'
  import { onMount } from 'svelte'

  export let id: string
  export let lang: LangType
  export let isOOP: boolean = false

  const t = useTranslations(lang)

  const AdSenseInFeedRegex = /In-Feed/

  const isAdSenseInFeed = AdSenseInFeedRegex.test(id)

  const adSenseSlot = adSenseInFeed[id as keyof typeof adSenseInFeed]

  onMount(() => {
    if (!isAdSenseInFeed) {
      window.googletag.cmd.push(function () {
        window.googletag.display(id)

        if (isOOP) {
          // Set a timeout to delay the execution of the following code block.
          // This is necessary because we need to ensure that the Google Tag services
          // have finished rendering the ads before we attempt to manipulate the DOM.
          setTimeout(() => {
            let bcRawData
            let data: BrandedContentType

            // Try to find the mobile branded content element by ID and extract its content.
            if (document.getElementById(`mkgpt_adtype_57167146_mobilebc_/6929183/${id}`)?.innerHTML) {
              bcRawData = document.getElementById(`mkgpt_adtype_57167146_mobilebc_/6929183/${id}`)?.innerHTML
            }

            // If the mobile content is not found, try to find the desktop branded content element by ID.
            if (document.getElementById(`mkgpt_adtype_57167146_desktopbc_/6929183/${id}`)?.innerHTML) {
              bcRawData = document.getElementById(`mkgpt_adtype_57167146_desktopbc_/6929183/${id}`)?.innerHTML
            }

            // Parse the branded content data from the raw HTML if available.
            data = bcRawData && JSON.parse(bcRawData)

            const brandedContentListElement = document.getElementById(`bc-list-entry-${id}`)

            const brandedContentCardElement = document.getElementById(`bc-card-${id}`)

            // If the list element is present in the DOM and we have data, create a new BrandedContent.svelte component.
            if (brandedContentListElement && data) {
              new BrandedContent({
                target: brandedContentListElement,
                props: {
                  data
                }
              })
            }
            // If the card element is present in the DOM and we have data, create a new BrandedContentCard.svelte instance.
            if (brandedContentCardElement && data) {
              new BrandedContentCard({
                target: brandedContentCardElement,
                props: {
                  data
                }
              })
            }
          }, 2000) // The delay is set to 2000 milliseconds (2 seconds).
        }
      })
    }
  })
</script>

{#if isOOP}
  <div {id}></div>
{:else if isAdSenseInFeed}
  <AdSenseInFeed adSlot={adSenseSlot} />
{:else}
  <div
    class="my-5 flex min-h-64 flex-col items-center justify-center gap-y-2 text-sm text-slate-400 sm:my-0 print:hidden"
  >
    {t('ads')}
    <div {id}></div>
  </div>
{/if}
