<script lang="ts">
  import type { BrandedContentType } from '@customTypes/brandedContent'

  export let data: BrandedContentType

  const { ad_click, dest_uri, ad_view, bc_title, bc_label, img_click } = data
</script>

<div class="group mx-auto w-full rounded-lg border border-slate-200 p-3 shadow duration-300 hover:shadow-lg">
  <a
    href={`${ad_click}${dest_uri}`}
    class={` flex flex-col gap-y-5`}
  >
    <span
      class="me-2 w-fit rounded bg-gradient-to-r from-brandBlue to-brandOrange px-2.5 py-1 text-white duration-300 group-hover:shadow-md"
    >
      {@html bc_label}
    </span>
    <img
      src={img_click}
      class="flex-none overflow-hidden rounded-lg object-cover"
      alt=""
      loading="lazy"
    />

    <h3 class="mx-auto text-lg font-semibold text-slate-800 duration-300 group-hover:text-brandOrange">
      {@html bc_title}
    </h3>
    <iframe
      src={`${ad_view}${dest_uri}`}
      title={bc_title}
      class="size-px"
    />
  </a>
</div>
