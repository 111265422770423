<script lang="ts">
  import type { BrandedContentType } from '@customTypes/brandedContent'
  export let data: BrandedContentType

  const { ad_click, dest_uri, ad_view, bc_title, bc_label, img_click } = data
</script>

<a
  href={`${ad_click}${dest_uri}`}
  class="group mx-auto flex flex-row gap-x-3 border-t border-slate-400 py-3 @3xl:gap-x-5 @3xl:py-3"
>
  <img
    src={img_click}
    class=": aspect-[4.5/3] h-20 w-32 flex-none overflow-hidden rounded object-cover @3xl:h-36 @3xl:w-56"
    alt=""
    loading="lazy"
  />

  <div class="flex flex-col justify-between gap-y-2 @3xl:justify-start @3xl:gap-y-3">
    <div class="gap-y-1">
      <span
        class="mb-2 me-2 inline-block rounded bg-gradient-to-r from-brandBlue to-brandOrange px-2.5 py-1 text-sm text-white duration-300 group-hover:shadow-md @3xl:text-base"
      >
        {@html bc_label}
      </span>

      <h3
        class="text-base font-semibold text-slate-800 underline-offset-4 duration-300 group-hover:text-brandOrange group-hover:underline @3xl:text-lg"
      >
        {@html bc_title}
      </h3>
    </div>
  </div>
  <iframe
    class="size-px"
    src={`${ad_view}${dest_uri}`}
    title={bc_title}
  ></iframe>
</a>
